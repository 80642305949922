.my-outer{
    width: 100%;
    margin-top: -50px;
    overflow: hidden;
    height: 545px;
}
.my-nfts{
    padding: 10px;
    width: 100%;
    background-color: rgb(225, 225, 225);
    box-shadow: 0 0 5px 2px rgb(230, 230, 230) ;
    border-radius: 10px;
    overflow-y: hidden;
    overflow-x: hidden;
    justify-items: center;
    height: 500px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    gap: 10px;
    overflow-y: scroll;
}
.my-outer .pagination{
    margin-left: -5%;
    
}