.out{
    margin-top: -50px;
    align-items: center!important;
    justify-content: center!important;
    width: 100%!important;
    height: 560px!important;
    margin-left: 20px;

}
.out .contain{
    margin-top: 10px;
    display: grid!important;
    gap: 10px;
    height: 540px!important;
    grid-template-columns: 100%!important;
}
.nfts{
    margin-top: 10px;
    background-color: rgb(225, 225, 225);
    box-shadow: 0 0 5px 2px rgb(230, 230, 230) ;
    border: 2px solid rgb(222, 222, 222);
    border-radius: 0 10px 10px 0;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-items: center;
    row-gap: 20px;
    width: 99.5%;
    height: 540px;
    overflow-y: scroll;
}

.pagination{
    transform: translate(42.5%,10px);
    margin-bottom: 100px;
}