.container {
    width: 100%!important;
    margin-top: -60px!important;
    display: grid!important;
    grid-template-columns: 100%!important;
    justify-content: center!important;
    align-items: center!important;
}
.container .header {
    display: block;
    margin-left: 17.5%;
    width: 65%;
    height: 450px;
    background-color: rgb(164, 23, 189,0.5);
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgb(151, 164, 175);
}
.header img{
    width: 100%;
    height: 100%;
}
.header .cover{
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: -450px;
    width: 100%;
    height: 450px;
    background-color: rgba(69, 111, 127);
    opacity: 0.7;
}
.header .description{
    font-size: 50px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    padding-top: 75px;
    justify-content: center;
    text-align: center;
    width: 100%;
    opacity: 1;
    margin-bottom: -80px;
}
.moreinfo{
    margin: 100px 50%;
    transform: translate(-50%, -35%);
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    border-radius: 10px;
    height: 50px;
    width: 100px;
    border: none;
    background-color: rgba(37, 57, 205, 0.8);
    opacity: 1;
}
.moreinfo:hover {
    color: rgba(255, 255, 255, 1);
    background-color: rgb(37, 57, 250, 1);
}
.container>.inner{
    display: block;
    margin-left: 50%;
    transform: translate(-50%);
    display: block;
    width: 80%;
    height: auto;
    background-color: transparent;
}
.sponsor{
    padding-bottom: 40px;
    padding-top: 10px;
    border: 2px solid rgb(228, 228, 228);
    border-left: none;
    border-right: none;
    justify-content: center;
}
.sponsor .logo{
    display: block;
    border-radius: 50%;
    margin-left: 40%;
    transform: translate(-60%);
    float: left;
    width: 60px;
    height: 60px;
    background-color: rgb(249, 54, 54);
}

.sponsor>p{
    margin-left: 40%;
    margin-top: -10px;
    font-size: x-large;
    text-align: left;
    margin-bottom: -30px;
}
.sponsor .border{
    margin-bottom: 25px;
    margin-left: 50%;
    transform: translate(-50%);
    width: 30%;
    border-bottom: 2px solid rgb(222, 222, 222);
}
.sponsor .inner{
    display: grid;
    justify-content: center;
    grid-template-columns: 8% 8% 8% 8%;
    gap: 15%;
}
.sponsor .inner img{
    box-shadow: 0 1px 10px rgb(151, 164, 175);
    border-radius: 50%;
}
.animation {
    display: grid;
    grid-template-columns: 50% 50%;
    border-bottom: 2px solid rgb(228, 228, 228);
    padding-bottom: 30px;
}
.inner p{
    padding-top: 20px;
    padding-bottom: 50px;
    text-align: left;
}

@keyframes change-wp{
    from {
        margin-left: 10%;
        opacity: 0;   
    }
    to {
        opacity: 1;
        margin-left: 80%;
    }
}
@keyframes change-lock{
    from {
        margin-left: -10%;
        opacity: 0;  
    }
    to {
        margin-left: 60%;
        opacity: 1;
    }
}

.animation .wp{
    box-shadow: 0 1px 10px rgb(151, 164, 175);
    border-radius: 5px;
    margin-left: 10%;
    width: 200px;
    height: 350px;
    transition: all , 4s;
}
.animation .lock{
    width: 300px;
    box-shadow: 0 1px 10px rgb(151, 164, 175);
    border-radius: 5px;
    margin-top: 5%;
    margin-left: 50%;
    opacity: 1;
}
.animation:hover .wp{
    opacity: 0;
    margin-left: 80%;
}
.animation:hover .lock{
    opacity: 0  ;
    animation: change-lock 4s 2.2s 1;
}
.xx{
    font-size: larger;
    text-align: center;
}