.card-outer{
    overflow: hidden;
    position: relative;
    margin: 10px auto;
   
    border-radius: 15px;
    width: 200px;
    height: 300px;
    background-color: rgb(71, 94, 94);
}
.card-outer:hover{
    box-shadow: 3px 3px 5px 3px gray;
}

.sell-button{
    left: -25%;
    color: white;
    font-size: 100%;
    border: none;
    background-color: rgb(217, 103, 92);
    position: absolute;
    width: 150%;
    height: 15%;
    bottom: -16%;
    transition: bottom 0.4s;
}
.card-outer:hover > .sell-button{
    bottom: -0.05%;
}

.card-container{
    width: 220px;
    overflow: hidden;
}
.sell-sell{
    color: white;
    font-size: 100%;
    border: none;
    border-radius: 10px;
    background-color: rgb(217, 103, 92);
    width: 150px;
    height: 30px;
    margin-right: 10px;
}
.sell-sell:hover{
    background-color: rgb(230, 103, 92);
    box-shadow: 0px 0px 3px 3px rgb(210, 210, 210);
}
.card-image {
    width: 200px;
    height: 210px;
    background-color: white;
}
.card-image>img{
    width: 200px;
    height: 210px;
}
.buy-modal{
    height: 150px;
}
.modal-image{
    margin-right: 5px;
    float: left;
    width: 150px;
    border-radius: 10px;
}
.sell-modal>p{
    margin-bottom: 2px;
}
.card-info *{
    padding-top: 2px;
    color: rgba(240, 248, 255, 0.566);
    text-align: left;
    padding-left: 10px;
}
.card-info .card-name{
    padding-top: 5px;
    font-weight: 700;
    padding-left: 0;
    font-size: 20px;
    text-align: center;
}
